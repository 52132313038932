export const pageUrlGenerator = (page) =>
  `${window.location.protocol}//${window.location.host}${page}`;

export const SlotGames = {
  netenttab: {
    asgardianstones: {
      redirectUrl: "/casino/ezgne-asgardian-stones",
      code: "1100096",
      casino: "ezugi",
      imgUrl: "./images/slots/asgardianstones.webp",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Asgardian Stones",
      providerName: "slots",
    },
    secrets: {
      redirectUrl: "/casino/ezgne-secrets-of-atlantis",
      code: "1100106",
      casino: "ezugi",
      imgUrl: "./images/slots/secrets-of-atlantis.webp",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Secrets of Atlantis",
      providerName: "slots",
    },
    SteamTower: {
      redirectUrl: "/casino/ezgne-steam-tower",
      code: "1100043",
      casino: "ezugi",
      imgUrl: "./images/slots/Steam-Tower.webp",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Steam Tower",
      providerName: "slots",
    },
    DivinieFortuneMegaways: {
      redirectUrl: "/casino/ezgne-divine-fortune-megaways",
      code: "1100089",
      casino: "ezugi",
      imgUrl: "./images/slots/divine_fortune_megaways.webp",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Divine Fortune Megaways",
      providerName: "slots",
    },
    FruitShopMegaways: {
      redirectUrl: "/casino/ezgne-fruit-shop-megaways",
      code: "1100094",
      casino: "ezugi",
      imgUrl: "./images/slots/Fruit_Shop_Megaways.webp",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Fruit Shop Megaways",
      providerName: "slots",
    },
    GorillaKingdom: {
      redirectUrl: "/casino/ezgne-gorilla-kingdom",
      code: "1100088",
      casino: "ezugi",
      imgUrl: "./images/slots/Gorilla_Kingdom.webp",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Gorilla Kingdom",
      providerName: "slots",
    },
    ReelKingMegaways: {
      redirectUrl: "/casino/ezgrt-reel-king-mega",
      code: "1100052",
      casino: "ezugi",
      imgUrl: "./images/slots/Reel_King_Mega.webp",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Reel King Mega",
      providerName: "slots",
    },
    Christmas: {
      redirectUrl: "/casino/ezgne-aloha-christmas",
      code: "1100032",
      casino: "ezugi",
      imgUrl: "./images/slots/Aloha-Christmas.webp",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Aloha! Christmas",
      providerName: "slots",
    },
    ClusterPays: {
      redirectUrl: "/casino/ezgne-aloha-cluster-pays",
      code: "1100033",
      casino: "ezugi",
      imgUrl: "./images/slots/Aloha-Cluster-Pays.webp",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Aloha! Cluster Pays",
      providerName: "slots",
    },
    WildWest: {
      redirectUrl: "/casino/ezgne-wild-wild-west-the-great-train-heist",
      code: "1100038",
      casino: "ezugi",
      imgUrl: "./images/slots/Wild-Wild-West-The-Great-Train-Heist.webp",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Wild Wild West: The Great Train Heist",
      providerName: "slots",
    },
    TwinSpinMegaways: {
      redirectUrl: "/casino/ezgne-twin-spin-megaways",
      code: "1100039",
      casino: "ezugi",
      imgUrl: "./images/slots/Twin_Spin_Megaways.webp",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Twin Spin Megaways",
      providerName: "slots",
    },
    TurnFortune: {
      redirectUrl: "/casino/ezgne-turn-your-fortune",
      code: "1100041",
      casino: "ezugi",
      imgUrl: "./images/slots/Turn_Your_Fortune.webp",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Turn Your Fortune",
      providerName: "slots",
    },
    Pyramid: {
      redirectUrl: "/casino/ezgne-pyramid-quest-for-immortality",
      code: "1100078",
      casino: "ezugi",
      imgUrl: "./images/slots/Pyramid-Quest-for-Immortality.webp",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Pyramid: Quest for Immortality",
      providerName: "slots",
    },
    PiggyRiches: {
      redirectUrl: "/casino/ezgne-piggy-riches",
      code: "1100080",
      casino: "ezugi",
      imgUrl: "./images/slots/Piggy-Riches.webp",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Piggy Riches",
    },
    DarkKing: {
      redirectUrl: "/casino/ezgne-dark-king-forbidden-riches",
      code: "1100081",
      casino: "ezugi",
      imgUrl: "./images/slots/Dark-King-Forbidden-Riches.webp",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Dark King: Forbidden Riches",
      providerName: "slots",
    },
    JingleSpin: {
      redirectUrl: "/casino/ezgne-jingle-spin",
      code: "1100082",
      casino: "ezugi",
      imgUrl: "./images/slots/Jingle-Spin.webp",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Jingle Spin",
      providerName: "slots",
    },
    JackHammer: {
      redirectUrl: "/casino/ezgne-jack-hammer",
      code: "1100084",
      casino: "ezugi",
      imgUrl: "./images/slots/Jack-Hammer.webp",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Jack Hammer",
      providerName: "slots",
    },
    DeadorAlive: {
      redirectUrl: "/casino/ezgne-dead-or-alive",
      code: "1100085",
      casino: "ezugi",
      imgUrl: "./images/slots/Dead-or-Alive.webp",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Dead or Alive",
      providerName: "slots",
    },
    DeadorAlive2: {
      redirectUrl: "/casino/ezgne-dead-or-alive-2",
      code: "1100087",
      casino: "ezugi",
      imgUrl: "./images/slots/Dead-or-Alive-2.webp",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Dead or Alive 2",
      providerName: "slots",
    },
    JackandtheBeanstalk: {
      redirectUrl: "/casino/ezgne-jack-and-the-beanstalk",
      code: "1100086",
      casino: "ezugi",
      imgUrl: "./images/slots/Jack-and-the-Beanstalk.webp",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Jack and the Beanstalk",
      providerName: "slots",
    },
    Elements: {
      redirectUrl: "/casino/ezgne-elements-the-awakening",
      code: "1100091",
      casino: "ezugi",
      imgUrl: "./images/slots/Elements-The-Awakening.webp",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Elements: The Awakening",
      providerName: "slots",
    },
    FinnandtheSwirlySpin: {
      redirectUrl: "/casino/ezgne-finn-and-the-swirly-spin",
      code: "1100093",
      casino: "ezugi",
      imgUrl: "./images/slots/Finn-and-the-Swirly-Spin.webp",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Finn and the Swirly Spin",
      providerName: "slots",
    },
    FruitShop: {
      redirectUrl: "/casino/ezgne-fruit-shop",
      code: "1100095",
      casino: "ezugi",
      imgUrl: "./images/slots/Fruit-Shop.webp",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Fruit Shop",
      providerName: "slots",
    },
    DeadorAlive2FeatureBuy: {
      redirectUrl: "/casino/ezgne-dead-or-alive-2-feature-buy",
      code: "1100100",
      casino: "ezugi",
      imgUrl: "./images/slots/Dead-or-Alive-2-Feature-Buy.webp",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Dead or Alive 2 Feature Buy",
      providerName: "slots",
    },

    name: "Netent",
    key: "netent",
  },

  redtigertab: {
    NinjaWays: {
      redirectUrl: "/casino/ezgrt-ninja-ways",
      code: "1100166",
      casino: "ezugi",
      imgUrl: "./images/slots/Ninja-Ways.webp",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Ninja Ways",
      providerName: "slots",
    },
    PathofDestiny: {
      redirectUrl: "/casino/ezgrt-path-of-destiny",
      code: "1100167",
      casino: "ezugi",
      imgUrl: "./images/slots/Path-of-Destiny.webp",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Path of Destiny",
      providerName: "slots",
    },
    PersianFortune: {
      redirectUrl: "/casino/ezgrt-persian-fortune",
      code: "1100168",
      casino: "ezugi",
      imgUrl: "./images/slots/Persian-Fortune.webp",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Persian Fortune",
      providerName: "slots",
    },
    ReelHeist: {
      redirectUrl: "/casino/ezgrt-reel-heist",
      code: "1100169",
      casino: "ezugi",
      imgUrl: "./images/slots/Reel-Heist.webp",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Reel Heist",
      providerName: "slots",
    },
    RobinHoodWildForest: {
      redirectUrl: "/casino/ezgrt-robin-hoods-wild-forest",
      code: "1100170",
      casino: "ezugi",
      imgUrl: "./images/slots/Robin-Hood`s-Wild-Forest.webp",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Robin Hood`s Wild Forest",
      providerName: "slots",
    },
    SnowWild: {
      redirectUrl: "/casino/ezgrt-snow-wild-and-the-7-features",
      code: "1100171",
      casino: "ezugi",
      imgUrl: "./images/slots/Snow-Wild-And-The-7-Features.webp",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Snow Wild And The 7 Features",
      providerName: "slots",
    },
    SylvanSpirits: {
      redirectUrl: "/casino/ezgrt-sylvan-spirits",
      code: "1100172",
      casino: "ezugi",
      imgUrl: "./images/slots/Sylvan-Spirits.webp",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Sylvan Spirits",
      providerName: "slots",
    },
    TheGreatestTrainRobbery: {
      redirectUrl: "/casino/ezgrt-the-greatest-train-robbery",
      code: "1100173",
      casino: "ezugi",
      imgUrl: "./images/slots/The-Greatest-Train-Robbery.webp",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "The Greatest Train Robbery",
      providerName: "slots",
    },
    TheWildHatter: {
      redirectUrl: "/casino/ezgrt-the-wild-hatter",
      code: "1100174",
      casino: "ezugi",
      imgUrl: "./images/slots/The-Wild-Hatter.webp",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "The Wild Hatter",
      providerName: "slots",
    },
    ThorLightning: {
      redirectUrl: "/casino/ezgrt-thors-lightning",
      code: "1100175",
      casino: "ezugi",
      imgUrl: "./images/slots/Thor`s-Lightning.webp",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Thor`s Lightning",
      providerName: "slots",
    },
    DragonLuck: {
      redirectUrl: "/casino/ezgrt-dragons-luck",
      code: "1100001",
      casino: "ezugi",
      imgUrl: "./images/slots/Dragon's-Luck.webp",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Dragon's Luck",
      providerName: "slots",
    },
    DragonLuckReel: {
      redirectUrl: "/casino/ezgrt-dragons-luck-power-reels",
      code: "1100002",
      casino: "ezugi",
      imgUrl: "./images/slots/Dragon's-Luck-Power-Reels.webp",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Dragon's Luck Power Reels",
      providerName: "slots",
    },
  },
};
