export const pageUrlGenerator = (page) =>
  `${window.location.protocol}//${window.location.host}${page}`;

export const casinoSection = {
  popularGames: {
    AndarBaharVivo: {
      redirectUrl: "/casino/vivo-andar-bahar",
      code: "",
      casino: "vivo",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/Vivo/AndarBaharVivo.webp",
      alt: "vivo andar bahar",
      name: "Vivo Andar Bahar",
      providerName: "Vivo",
    },
    TeenPattiVivo: {
      redirectUrl: "/casino/vivo-teen-patti",
      code: "",
      casino: "vivo",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/Vivo/TeenPattiVivo.webp",
      alt: "vivo teen patti",
      name: "Vivo Teen Patti",
      providerName: "Vivo",
    },
    UltimateAndarBahar: {
      redirectUrl: "/casino/ezg-ultimate-andar-bahar",
      code: "328000",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/andarbahar/ultimateAB.webp",
      alt: "",
      name: "Ultimate Andar Bahar",
      providerName: "Ezugi",
    },
    AndarBahar: {
      redirectUrl: "/casino/ezg-andar-bahar",
      code: "328000",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/andarbahar/andarbahar.webp",
      alt: "",
      name: "Andar Bahar",
      providerName: "Ezugi",
    },
    aviator: {
      redirectUrl: "/casino/spribe/aviator",
      code: "aviator",
      casino: "spribe",
      provider: "aviator",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/spribe/Aviator.webp",
      alt: "",
      name: "Aviator",
      providerName: "Spribe",
    },
    AutoRoulette: {
      redirectUrl: "/casino/ezg-auto-roulette",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/autoroulette.webp",
      alt: "",
      name: "Auto Roulette",
      providerName: "Ezugi",
    },
    SpeedRoulette: {
      redirectUrl: "/casino/ezgevo-speed-roulette",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/Speedroulette.webp",
      alt: "",
      name: "Speed Roulette",
      providerName: "Evolution",
    },
    DragonTiger: {
      redirectUrl: "/casino/ezg-dragon-tiger",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/popular/Dragontigerr.webp",
      alt: "",
      name: "Dragon Tiger",
      providerName: "Evolution",
    },
    NoCommissionBaccarat: {
      redirectUrl: "/casino/ezg-no-commission-baccarat",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/no-commission-baccarat.webp",
      alt: "",
      name: "No Commission Baccarat",
      providerName: "Evolution",
    },
    Lucky7: {
      redirectUrl: "/casino/ezg-lucky-7",
      code: "227103",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/popular/lucky.webp",
      alt: "",
      name: "Lucky 7",
      providerName: "Ezugi",
    },
    Onedayteenpatti1: {
      redirectUrl: "/casino/ezg-one-day-teen-patti",
      code: "227103",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/poker/Onedayteenpatti.webp",
      alt: "",
      name: "One Day Teen Patti",
      providerName: "Ezugi",
    },
    CricketWar: {
      redirectUrl: "/casino/ezg-cricket-war",
      code: "227103",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/popular/CricketWar.webp",
      alt: "",
      name: "Cricket War",
      providerName: "Ezugi",
    },
    CricketAutoRoulette: {
      redirectUrl: "/casino/ezg-cricket-auto-roulette",
      code: "227103",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/CricketAutoRoulette.webp",
      alt: "",
      name: "Cricket Auto Roulette",
      providerName: "Ezugi",
    },
    lightningsicbo: {
      redirectUrl: "/casino/ezgevo-gonzos-lightning-sic-bo",
      code: "227103",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/popular/lightningsicbo.webp",
      alt: "",
      name: "Lightning Sic Bo",
      providerName: "Evolution",
    },
    UltimateRoulette: {
      redirectUrl: "/casino/ezg-ultimate-roulette",
      code: "227103",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/UltimateRoulette.webp",
      alt: "",
      name: "Ultimate Roulette",
      providerName: "Ezugi",
    },
  },

  AndarBahar: {
    OTTAndarBahar: {
      redirectUrl: "/casino/ezg-casino-marina-andar-bahar",
      code: "328000",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/andarbahar/OTTAndarBahar.webp",
      alt: "",
      name: "OTT Andar Bahar",
      providerName: "Ezugi",
    },
    AndarBahar: {
      redirectUrl: "/casino/ezg-andar-bahar",
      code: "328000",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/andarbahar/andarbahar.webp",
      alt: "",
      name: "Andar Bahar",
      providerName: "Ezugi",
    },
    UltimateAndarBahar: {
      redirectUrl: "/casino/ezg-ultimate-andar-bahar",
      code: "328000",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/andarbahar/ultimateAB.webp",
      alt: "",
      name: "Ultimate Andar Bahar",
      providerName: "Ezugi",
    },
  },

  roulettetab: {
    PortomasoRouletteVivo: {
      redirectUrl: "/casino/vivo-portomaso-roulette",
      code: "",
      casino: "vivo",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/Vivo/vivo-portomaso-roulette.webp",
      alt: "vivo portomaso roulette",
      name: "Vivo Portomaso Roulette",
      providerName: "Vivo",
    },
    OracleBlazeRouletteVivo: {
      redirectUrl: "/casino/vivo-oracle-blaze-roulette",
      code: "",
      casino: "vivo",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/Vivo/vivo-oracle-blaze-roulette.webp",
      alt: "vivo oracle blaze roulette",
      name: "Vivo Oracle Blaze Roulette",
      providerName: "Vivo",
    },
    LasVegasRouletteVivo: {
      redirectUrl: "/casino/vivo-las-vegas-roulette",
      code: "",
      casino: "vivo",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/Vivo/vivo-las-vegas-roulette.webp",
      alt: "vivo las vegas roulette",
      name: "Vivo Las Vegas Roulette",
      providerName: "Vivo",
    },
    AutoRoulette: {
      redirectUrl: "/casino/ezg-auto-roulette",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/autoroulette.webp",
      alt: "",
      name: "Auto Roulette",
      providerName: "Ezugi",
    },
    SpeedAutoRoulette: {
      redirectUrl: "/casino/ezg-speed-auto-roulette",
      code: "221002",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/Speedautoroullete.webp",
      alt: "",
      name: "Speed Auto Roulette",
      providerName: "Ezugi",
    },
    NamasteRoulette: {
      redirectUrl: "/casino/ezg-namaste-roulette",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/namasteroulette1.webp",
      alt: "",
      name: "Namaste Roulette",
      providerName: "Ezugi",
    },
    UltimateRoulette: {
      redirectUrl: "/casino/ezg-ultimate-roulette",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/UltimateRoulette.webp",
      alt: "",
      name: "Ultimate Roulette",
      providerName: "Ezugi",
    },
  },

  dragontiger: {
    DragonTigerVivo: {
      redirectUrl: "/casino/vivo-dragon-tiger",
      code: "",
      casino: "vivo",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/Vivo/vivo-dragon-tiger.webp",
      alt: "vivo dragon tiger",
      name: "Vivo Dragon Tiger",
      providerName: "Vivo",
    },
    DragonTiger: {
      redirectUrl: "/casino/ezg-dragon-tiger",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/popular/Dragontigerr.webp",
      alt: "",
      name: "Dragon Tiger",
      providerName: "Ezugi",
    },
  },

  baccarattab: {
    MacauBaccaratVivo: {
      redirectUrl: "/casino/vivo-macau-baccarat",
      code: "",
      casino: "vivo",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/Vivo/vivo-macau-baccarat.webp",
      alt: "vivo macau baccarat",
      name: "Vivo Macau Baccarat",
      providerName: "Vivo",
    },
    VABacarat1: {
      redirectUrl: "/casino/vivo-va-bacarat-1",
      imgUrl: "./images/Vivo/VABacarat1.webp",
      name: "VA Bacarat 1",
      providerName: "Vivo",
    },
    VABacarat2: {
      redirectUrl: "/casino/vivo-va-bacarat-2",
      imgUrl: "./images/Vivo/VABacarat2.webp",
      name: "VA Bacarat 2",
      providerName: "Vivo",
    },
    VABacarat3: {
      redirectUrl: "/casino/vivo-va-bacarat-3",
      imgUrl: "./images/Vivo/VABacarat3.webp",
      name: "VA Bacarat 3",
      providerName: "Vivo",
    },
    VABacarat4: {
      redirectUrl: "/casino/vivo-va-bacarat-4",
      imgUrl: "./images/Vivo/VABacarat4.webp",
      name: "VA Bacarat 4",
      providerName: "Vivo",
    },
    VABacarat5: {
      redirectUrl: "/casino/vivo-va-bacarat-5",
      imgUrl: "./images/Vivo/VABacarat5.webp",
      name: "VA Bacarat 5",
      providerName: "Vivo",
    },
    Baccarat: {
      redirectUrl: "/casino/ezg-baccarat",
      code: "101",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/baccaratezugi.webp",
      alt: "",
      name: "Baccarat",
      providerName: "Ezugi",
    },
    FortuneBaccarat: {
      redirectUrl: "/casino/ezg-fortune-baccarat",
      code: "101",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/Fortunebaccarat.webp",
      alt: "",
      name: "Fortune Baccarat",
      providerName: "Ezugi",
    },
    NoCommissionBaccarat: {
      redirectUrl: "/casino/ezg-no-commission-baccarat",
      code: "170",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/no-commission-baccarat.webp",
      alt: "",
      name: "No Commission Baccarat",
      providerName: "Ezugi",
    },
    SpeedCricketBaccarat: {
      redirectUrl: "/casino/ezgevo-speed-baccarat-x",
      code: "101",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/SpeedBaccaratx.webp",
      alt: "",
      name: "Speed Baccarat X",
      providerName: "Evolution",
    },
  },

  blackjacktab: {
    LasVegasBlackjackVivo: {
      redirectUrl: "/casino/vivo-las-vegas-blackjack",
      code: "",
      casino: "vivo",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/Vivo/vivo-las-vegas-blackjack.webp",
      alt: "vivo las vegas blackjack",
      name: "Vivo Las Vegas Blackjack",
      providerName: "Vivo",
    },
    BlackjackSilverD: {
      redirectUrl: "/casino/ezgevo-blackjack-silver-d",
      code: "1000044",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/Blackjack-Silver-D.webp",
      alt: "",
      name: "Blackjack Silver D",
      providerName: "Evolution",
    },
    BlackjackVIPE: {
      redirectUrl: "/casino/ezgevo-blackjack-vip-e",
      code: "1000052",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/Blackjack-VIP-E.webp",
      alt: "",
      name: "Blackjack VIP E",
      providerName: "Evolution",
    },
    SpeedBlackjackD: {
      redirectUrl: "/casino/ezgevo-speed-blackjack-d",
      code: "1000033",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/Speed-Blackjack-D1.webp",
      alt: "",
      name: "Speed Blackjack D",
      providerName: "Evolution",
    },
    BlackjackVIPL: {
      redirectUrl: "/casino/ezgevo-blackjack-vip-l",
      code: "1000056",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/Blackjack-VIP-L.webp",
      alt: "",
      name: "Blackjack VIP L",
      providerName: "Evolution",
    },
    ClassicSpeedBlackjack3: {
      redirectUrl: "/casino/ezgevo-classic-speed-blackjack-3",
      code: "1000228",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/Speed-Blackjack-3.webp",
      alt: "",
      name: " Classic Speed Blackjack 3  ",
      providerName: "Evolution",
    },
  },

  pokertab: {
    Hand2CasinoHoldem: {
      redirectUrl: "/casino/ezgevo-2-hand-casino-holdem",
      code: "1000073",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/poker/2handcasinoholem.webp",
      alt: "",
      name: " 2 Hand Casino Holdem",
      providerName: "Evolution",
    },
    BetonTeenPatti: {
      redirectUrl: "/casino/ezg-bet-on-teen-patti",
      code: "227101",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/poker/Betonteenpatti.webp",
      alt: "",
      name: "Bet on Teen Patti",
      providerName: "Ezugi",
    },
    TeenPatti3card: {
      redirectUrl: "/casino/ezg-teen-patti-3-card",
      code: "227100",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/poker/Teenpatti3card.webp",
      alt: "",
      name: " Teen Patti 3 Card",
      providerName: "Ezugi",
    },
    TexasHoldembonus: {
      redirectUrl: "/casino/ezgevo-texas-holdem-bonus-poker",
      code: "1000111",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/poker/TexasHoldembonus.webp",
      alt: "",
      name: "Texas Holdem Bonus",
      providerName: "Evolution",
    },
  },

  spribe: {
    aviator: {
      redirectUrl: "/casino/spribe/aviator",
      code: "aviator",
      casino: "spribe",
      provider: "aviator",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/spribe/Aviator.webp",
      alt: "",
      name: "Aviator",
      providerName: "Spribe",
    },
    mines: {
      redirectUrl: "/casino/spribe/mines",
      code: "mines",
      casino: "spribe",
      provider: "mines",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/spribe/Mines.webp",
      alt: "",
      name: "Mines",
      providerName: "Spribe",
    },
    goal: {
      redirectUrl: "/casino/spribe/goal",
      code: "goal",
      casino: "spribe",
      provider: "goal",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/spribe/Goal.webp",
      alt: "",
      name: "Goal",
      providerName: "Spribe",
    },
    dice: {
      redirectUrl: "/casino/spribe/dice",
      code: "dice",
      casino: "spribe",
      provider: "dice",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/spribe/Dice.webp",
      alt: "",
      name: "Dice",
      providerName: "Spribe",
    },
    plinko: {
      redirectUrl: "/casino/spribe/plinko",
      code: "plinko",
      casino: "spribe",
      provider: "plinko",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/spribe/Plinko.webp",
      alt: "",
      name: "Plinko",
      providerName: "Spribe",
    },
    miniroulette: {
      redirectUrl: "/casino/spribe/miniroulette",
      code: "mini-roulette",
      casino: "spribe",
      provider: "mini-roulette",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/spribe/MiniROulette.webp",
      alt: "",
      name: "Mini Roulette",
      providerName: "Spribe",
    },
    hilo: {
      redirectUrl: "/casino/spribe/hilo",
      code: "hi-lo",
      casino: "spribe",
      provider: "hi-lo",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/spribe/Hilo.webp",
      alt: "",
      name: "Hilo",
      providerName: "Spribe",
    },
  },

  supernowa: {
    RNGCasinoQueen: {
      redirectUrl: "/casino/sn-rng-casino-queen",
      code: "RCQ",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGCasinoQueen.webp",
      alt: "",
      name: "RNG Casino Queen",
      providerName: "Supernowa",
    },
    RNGCasinoQueen2020: {
      redirectUrl: "/casino/sn-rng-casino-queen-2020",
      code: "RCQ20",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGCasinoQueen2020.webp",
      alt: "",
      name: "RNG Casino Queen 2020",
      providerName: "Supernowa",
    },
    RNGDragonTiger: {
      redirectUrl: "/casino/sn-rng-dragon-tiger",
      code: "VDT",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNG-Dragon-Tiger.webp",
      alt: "",
      name: "RNG Dragon Tiger",
      providerName: "Supernowa",
    },
    RNGDragonTiger2020: {
      redirectUrl: "/casino/sn-rng-dragon-tiger-2020",
      code: "VDT20",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGDragonTiger2020.webp",
      alt: "",
      name: "RNG Dragon Tiger 2020",
      providerName: "Supernowa",
    },
    RNGJoker: {
      redirectUrl: "/casino/sn-rng-joker",
      code: "VJKR",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGJoker.webp",
      alt: "",
      name: "RNG Joker",
      providerName: "Supernowa",
    },
    RNGKingRace: {
      redirectUrl: "/casino/sn-rng-king-race",
      code: "VCR",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGKingRace.webp",
      alt: "",
      name: "RNG King Race",
      providerName: "Supernowa",
    },
    RNGLucky7: {
      redirectUrl: "/casino/sn-rng-lucky-7",
      code: "VUD7",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGLucky7.webp",
      alt: "",
      name: "RNG Lucky 7",
      providerName: "Supernowa",
    },
    RNGTeenPatti: {
      redirectUrl: "/casino/sn-rng-teen-patti",
      code: "VTP",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGTeenPatti.webp",
      alt: "",
      name: "RNG Teen Patti",
      providerName: "Supernowa",
    },
    RNGTeenPatti2020: {
      redirectUrl: "/casino/sn-rng-teen-patti-2020",
      code: "VTP20",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGTeenPatti2020.webp",
      alt: "",
      name: "RNG Teen Patti 2020",
      providerName: "Supernowa",
    },
    RNGWorliMatka: {
      redirectUrl: "/casino/sn-rng-worli-matka",
      code: "VWM",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGWorliMatka.webp",
      alt: "",
      name: "RNG Worli Matka",
      providerName: "Supernowa",
    },
  },
};
