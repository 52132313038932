import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import { logoutUser } from "../../../../redux/auth/actions";

import LogoutIcon from "../../../../assets/images/icons/logout.svg";
import ExchangeIconUpdated from "../../../../assets/images/icons/exchange.webp";
import HomeIconUpdated from "../../../../assets/images/icons/home.svg";
import CasinoIconUpdated from "../../../../assets/images/icons/casino.svg";
import DepositIconUpdated from "../../../../assets/images/icons/user.svg";

const Dashboard = () => {
  const { wallet } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const location = useLocation();
  const [activePage, setActivePage] = useState("");
  const footerPages = [
    "betby",
    "/sports/Inplay",
    "home",
    "/livecasino",
    "/dashboard",
    "/",
  ];
  useEffect(() => {
    console.log("location", location.pathname);
    const matchedValue = footerPages.find((f) =>
      location?.pathname.includes(f)
    );
    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);

  return (
    <div>
      <div>
        <HeaderAfterLogin />
      </div>
      <main className="main">
        <div className="Dashboard">
          <div className="DashboardHeader">
            <ul>
              <li>
                Balance: <span>{wallet?.balance}</span>
              </li>
            </ul>
          </div>
          <div className="DashboardBody">
            <ul>
              <li>
                <a href="/profile">Profile</a>
              </li>
              <li>
                <a href="/mybets">My Bets</a>
              </li>
              <li>
                <a href="/cashier">Account Statement</a>
              </li>
              <li>
                <a href="/referral">Referral</a>
              </li>
              <li>
                <a href="/gatewaylist">Deposit</a>
              </li>
              <li>
                <a href="/withdraw">Withdraw</a>
              </li>
              <li className="Logout">
                <a href="" onClick={() => dispatch(logoutUser())}>
                  <img src={LogoutIcon} alt="LogoutIcon" />
                  Sign Out
                </a>
              </li>
            </ul>
          </div>
        </div>
      </main>
      <footer className="footer">
        <div className="footer-menu position-fixed start-0 bottom-0 z-index-2 d-block d-lg-none">
          <ul className="d-flex justify-content-evenly m-0 p-0 list-unstyled">
            {/* <li className="exchange">
              <a
                className={activePage === "/sports/Inplay" ? "active" : ""}
                href="/sports/Inplay"
              >
                <div className="imgBox">
                  <img src={ExchangeIconUpdated} alt="SportsIcon" />
                </div>
                <span>Exchange</span>
              </a>
            </li> */}
            <li className="home">
              <a className={activePage === "home" ? "active" : ""} href="/home">
                <div className="imgBox">
                  <img src={HomeIconUpdated} alt="SportsIcon" />
                </div>
                <span>Home</span>
              </a>
            </li>
            <li className="casino">
              <a
                href="/livecasino"
                className={activePage === "/livecasino" ? "active" : ""}
              >
                <div className="imgBox">
                  <img src={CasinoIconUpdated} alt="SportsIcon" />
                </div>
                <span>Casino</span>
              </a>
            </li>
            <li className="deposit">
              <a
                className={activePage === "/dashboard" ? "active" : ""}
                href="/dashboard"
              >
                <div className="imgBox">
                  <img src={DepositIconUpdated} alt="SportsIcon" />
                </div>
                <span>Profile</span>
              </a>
            </li>
          </ul>
        </div>
      </footer>
    </div>
  );
};

export default Dashboard;
