import React from "react";
import { useSelector } from "react-redux";
import Header from "../../../../containers/Header";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import Footer from "../../../../containers/Footer";

import LuckyDrawBanner1 from "../../../../assets/images/banner/lucky-draw/LDB1.webp";
import Iphone16 from "../../../../assets/images/banner/lucky-draw/Iphone16ProMax.webp";
import Macbook from "../../../../assets/images/banner/lucky-draw/Macbook.webp";
import AppleVision from "../../../../assets/images/banner/lucky-draw/AppleVision.webp";
import VipLD4 from "../../../../assets/images/banner/lucky-draw/VipLD4.webp";
import VipLD5 from "../../../../assets/images/banner/lucky-draw/VipLD5.webp";

function LuckyDraw() {
  const { isAuth } = useSelector((state) => state.auth);

  return (
    <>
      {isAuth ? <HeaderAfterLogin /> : <Header />}

      <main className="main">
        <div className="vip-gifts lucky-draw">
          <div className="inner-box">
            <div className="content-box">
              {/* <div className="heading">Lucky Draw</div> */}

              {/* <h5>Top 5 depositor of the month</h5> */}
              <ul className="">
                <li>
                  <div className="">
                    <img src={LuckyDrawBanner1} alt="bronze" />
                  </div>
                </li>
                <li>
                  <div className="">
                    <img src={Iphone16} alt="bronze" />
                  </div>
                </li>
                <li>
                  <div className="">
                    <img src={Macbook} alt="silverr" />
                  </div>
                </li>
                <li>
                  <div className="">
                    <img src={AppleVision} alt="gold" />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </main>

      {!isAuth ? <BeforeLoginFooter /> : <Footer />}
    </>
  );
}

export default LuckyDraw;
