export const auraGames = {
  improvedExperience: {
    DreamCatcher: {
      code: "98567",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Dream Catcher",
      name: "Dream Catcher",
      providerName: "aura",
      imgUrl: "./images/aura/DreamCatcher.webp",
      redirectUrl: "/casino/aura-dream-catcher",
    },
    TeenpattiT20: {
      code: "56768",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Teenpatti T20",
      name: "Teenpatti T20",
      providerName: "aura",
      imgUrl: "./images/aura/TeenpattiT20.webp",
      redirectUrl: "/casino/aura-teenpatti-t20",
    },
    DragonTiger: {
      code: "98790",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Dragon Tiger",
      name: "Dragon Tiger",
      providerName: "aura",
      imgUrl: "./images/aura/DragonTiger.webp",
      redirectUrl: "/casino/aura-dragon-tiger",
    },
    UpDown7: {
      code: "98789",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "7 Up Down",
      name: "7 Up Down",
      providerName: "aura",
      imgUrl: "./images/aura/7UpDown.webp",
      redirectUrl: "/casino/aura-7-up-down",
    },
    HiLow: {
      code: "56968\n",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Hi Low",
      name: "Hi Low",
      providerName: "aura",
      imgUrl: "./images/aura/HiLow.webp",
      redirectUrl: "/casino/aura-hi-low",
    },
    Card2Teenpatti: {
      code: "67660",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "2 Card Teenpatti",
      name: "2 Card Teenpatti",
      providerName: "aura",
      imgUrl: "./images/aura/2CardsTeenpatti.webp",
      redirectUrl: "/casino/aura-2-card-teenpatti",
    },
    CasinoWar: {
      code: "67580",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Casino War",
      name: "Casino War",
      providerName: "aura",
      imgUrl: "./images/aura/CasinoWar.webp",
      redirectUrl: "/casino/aura-casino-war",
    },
    MuflisTeenpatti: {
      code: "67600",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Muflis Teenpatti",
      name: "Muflis Teenpatti",
      providerName: "aura",
      imgUrl: "./images/aura/MuflisTeenpatti.webp",
      redirectUrl: "/casino/aura-muflis-teenpatti",
    },
    AndarBahar: {
      code: "87564",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Andar Bahar",
      name: "Andar Bahar",
      providerName: "aura",
      imgUrl: "./images/aura/AndarBahar.webp",
      redirectUrl: "/casino/aura-andar-bahar",
    },
    QueenRace: {
      code: "67620",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Queen Race",
      name: "Queen Race",
      providerName: "aura",
      imgUrl: "./images/aura/QueenRace.webp",
      redirectUrl: "/casino/aura-queen-race",
    },
    Poker2020: {
      code: "67567",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Poker 20-20",
      name: "Poker 20-20",
      providerName: "aura",
      imgUrl: "./images/aura/Poker20-20.webp",
      redirectUrl: "/casino/aura-poker-20-20",
    },
    BollywoodCasino: {
      code: "67570",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Bollywood Casino",
      name: "Bollywood Casino",
      providerName: "aura",
      imgUrl: "./images/aura/BollywoodCasino.webp",
      redirectUrl: "/casino/aura-bollywood-casino",
    },
    Teenpatti1Day: {
      code: "56767",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Teenpatti 1 Day",
      name: "Teenpatti 1 Day",
      providerName: "aura",
      imgUrl: "./images/aura/TeenpattiOneDay.webp",
      redirectUrl: "/casino/aura-teenpatti-1-Day",
    },
    Roulette: {
      code: "98788",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Roulette",
      name: "Roulette",
      providerName: "aura",
      imgUrl: "./images/aura/Roulette.webp",
      redirectUrl: "/casino/aura-roulette",
    },
    AmarAkbarAnthony: {
      code: "98791",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Amar Akbar Anthony",
      name: "Amar Akbar Anthony",
      providerName: "aura",
      imgUrl: "./images/aura/AmarAkbarAnthony.webp",
      redirectUrl: "/casino/aura-amar-akbar-anthony",
    },
    TeenpattiTest: {
      code: "67630",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Teenpatti Test",
      name: "Teenpatti Test",
      providerName: "aura",
      imgUrl: "./images/aura/TeenpattiTest.webp",
      redirectUrl: "/casino/aura-teenpatti-test",
    },
    Sicbo: {
      code: "98566",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Sicbo",
      name: "Sicbo",
      providerName: "aura",
      imgUrl: "./images/aura/Sicbo.webp",
      redirectUrl: "/casino/aura-sicbo",
    },
  },
  cardGames: {
    Poker: {
      code: "67564",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Poker",
      name: "Poker",
      providerName: "aura",
      imgUrl: "./images/aura/Poker.webp",
      redirectUrl: "/casino/aura-poker",
    },
    SuperOver: {
      code: "67720",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Super Over",
      name: "Super Over",
      providerName: "aura",
      imgUrl: "./images/aura/SuperOver.webp",
      redirectUrl: "/casino/aura-sper-over",
    },
    Matka: {
      code: "92037",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Matka",
      name: "Matka",
      providerName: "aura",
      imgUrl: "./images/aura/Matka(Virtual).webp",
      redirectUrl: "/casino/aura-matka",
    },
    Baccarat: {
      code: "92038",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Baccarat",
      name: "Baccarat",
      providerName: "aura",
      imgUrl: "./images/aura/Baccarat.webp",
      redirectUrl: "/casino/aura-baccarat",
    },
    Trio: {
      code: "67610",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Trio",
      name: "Trio",
      providerName: "aura",
      imgUrl: "./images/aura/Trio.webp",
      redirectUrl: "/casino/aura-trio",
    },
    "3CardsJudgement": {
      code: "67670",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "3 Cards Judgement",
      name: "3 Cards Judgement",
      providerName: "aura",
      imgUrl: "./images/aura/3CardsJudgement.webp",
      redirectUrl: "/casino/aura-3-cards-judgement",
    },
    "Race20-20": {
      code: "90100",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Race 20-20",
      name: "Race 20-20",
      providerName: "aura",
      imgUrl: "./images/aura/Race20-20.webp",
      redirectUrl: "/casino/aura-race-20-20",
    },
    "29CardBaccarat": {
      code: "67690",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "29 Card Baccarat",
      name: "29 Card Baccarat",
      providerName: "aura",
      imgUrl: "./images/aura/29CardBaccarat.webp",
      redirectUrl: "/casino/aura-29-card-baccarat",
    },
    TeenpattiOpen: {
      code: "67640",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Teenpatti Open",
      name: "Teenpatti Open",
      providerName: "aura",
      imgUrl: "./images/aura/TeenpattiOpen.webp",
      redirectUrl: "/casino/aura-teenpatti-open",
    },
    Raceto17: {
      code: "67710",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Race to 17",
      name: "Race to 17",
      providerName: "aura",
      imgUrl: "./images/aura/Raceto17.webp",
      redirectUrl: "/casino/aura-race-to-17",
    },
    Sixplayerpoker: {
      code: "67565",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Six player poker",
      name: "Six player poker",
      providerName: "aura",
      imgUrl: "./images/aura/Sixplayerpoker.webp",
      redirectUrl: "/casino/aura-six-player-poker",
    },
    CasinoMeter: {
      code: "67575",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Casino Meter",
      name: "Casino Meter",
      providerName: "aura",
      imgUrl: "./images/aura/CasinoMeter.webp",
      redirectUrl: "/casino/aura-casino-meter",
    },
    TheTrap: {
      code: "67680",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "The Trap",
      name: "The Trap",
      providerName: "aura",
      imgUrl: "./images/aura/TheTrap.webp",
      redirectUrl: "/casino/aura-the-trap",
    },
  },
  virtualCardGames: {
    "7up&Down(Virtual)": {
      code: "98793",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "7 up & Down (Virtual)",
      name: "7 up & Down (Virtual)",
      providerName: "aura",
      imgUrl: "./images/aura/7up&Down(Virtual).webp",
      redirectUrl: "/casino/aura-7-up-&-down-virtual",
    },
    "DragonTiger(Virtual)": {
      code: "98794",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Dragon Tiger (Virtual)",
      name: "Dragon Tiger (Virtual)",
      providerName: "aura",
      imgUrl: "./images/aura/DragonTiger(Virtual).webp",
      redirectUrl: "/casino/aura-dragon-tiger-virtual",
    },
    "TeenpattiT20(Virtual)": {
      code: "56769",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Teenpatti T20 (Virtual)",
      name: "Teenpatti T20 (Virtual)",
      providerName: "aura",
      imgUrl: "./images/aura/TeenpattiT20(Virtual).webp",
      redirectUrl: "/casino/aura-teenpatti-t20-virtual",
    },
    "HiLow(Virtual)": {
      code: "56969",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Hi Low (Virtual)",
      name: "Hi Low (Virtual)",
      providerName: "aura",
      imgUrl: "./images/aura/HiLow(Virtual).webp",
      redirectUrl: "/casino/aura-hi-low-virtual",
    },
    "AmarAkbarAnthony(Virtual)": {
      code: "98795",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Amar Akbar Anthony (Virtual)",
      name: "Amar Akbar Anthony (Virtual)",
      providerName: "aura",
      imgUrl: "./images/aura/AmarAkbarAnthony(Virtual).webp",
      redirectUrl: "/casino/aura-amar-akbar-anthony-virtual",
    },
    "Roulette(Virtual)": {
      code: "98792",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Roulette (Virtual)",
      name: "Roulette (Virtual)",
      providerName: "aura",
      imgUrl: "./images/aura/Roulette(Virtual).webp",
      redirectUrl: "/casino/aura-roulette-virtual",
    },
    "AndarBahar(Virtual)": {
      code: "87565",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Andar Bahar (Virtual)",
      name: "Andar Bahar (Virtual)",
      providerName: "aura",
      imgUrl: "./images/aura/AndarBahar(Virtual).webp",
      redirectUrl: "/casino/aura-andar-bahar-virtual",
    },
    "32cardscasino(Virtual)": {
      code: "56966",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "32 cards casino (Virtual)",
      name: "32 cards casino (Virtual)",
      providerName: "aura",
      imgUrl: "./images/aura/32cardscasino(Virtual).webp",
      redirectUrl: "/casino/aura-32-cards-casino-virtual",
    },
    "Matka(Virtual)": {
      code: "92036",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Matka (Virtual)",
      name: "Matka (Virtual)",
      providerName: "aura",
      imgUrl: "./images/aura/Matka(Virtual).webp",
      redirectUrl: "/casino/aura-matka-virtual",
    },
    "TeenpattiOne-Day(Virtual)": {
      code: "56766",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Teenpatti One-Day (Virtual)",
      name: "Teenpatti One-Day (Virtual)",
      providerName: "aura",
      imgUrl: "./images/aura/TeenpattiOneDay(Virtual).webp",
      redirectUrl: "/casino/aura-teenpatti-one-day-virtual",
    },
    "Poker(Virtual)": {
      code: "67563",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Poker  (Virtual)",
      name: "Poker  (Virtual)",
      providerName: "aura",
      imgUrl: "./images/aura/Poker(Virtual).webp",
      redirectUrl: "/casino/aura-poker-virtual",
    },
    "Sixplayerpoker(Virtual)": {
      code: "67566",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Six player poker (Virtual)",
      name: "Six player poker (Virtual)",
      providerName: "aura",
      imgUrl: "./images/aura/Sixplayerpoker(Virtual).webp",
      redirectUrl: "/casino/aura-six-player-poker-virtual",
    },
    "Race20-20(Virtual)": {
      code: "90101",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Race 20-20 (Virtual)",
      name: "Race 20-20 (Virtual)",
      providerName: "aura",
      imgUrl: "./images/aura/Race20-20(Virtual).webp",
      redirectUrl: "/casino/aura-Race-20-20-virtual",
    },
    "MuflisTeenpatti(virtual)": {
      code: "67601",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Muflis Teenpatti (virtual)",
      name: "Muflis Teenpatti (virtual)",
      providerName: "aura",
      imgUrl: "./images/aura/MuflisTeenpatti(virtual).webp",
      redirectUrl: "/casino/aura-muflis-teenpatti-virtual",
    },
    "Poker20-20(Virtual)": {
      code: "67568",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Poker 20-20 (Virtual)",
      name: "Poker 20-20 (Virtual)",
      providerName: "aura",
      imgUrl: "./images/aura/Poker20-20(Virtual).webp",
      redirectUrl: "/casino/aura-poker-20-20-virtual",
    },
    "Trio(virtual)": {
      code: "67611",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Trio (virtual)",
      name: "Trio (virtual)",
      providerName: "aura",
      imgUrl: "./images/aura/Trio(virtual).webp",
      redirectUrl: "/casino/aura-trio-virtual",
    },
    "CasinoMeter(Virtual)": {
      code: "67576",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Casino Meter (Virtual)",
      name: "Casino Meter (Virtual)",
      providerName: "aura",
      imgUrl: "./images/aura/CasinoMeter(Virtual).webp",
      redirectUrl: "/casino/aura-casino-meter-virtual",
    },
    "TeenpattiTest(virtual)": {
      code: "67631",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Teenpatti Test (virtual)",
      name: "Teenpatti Test (virtual)",
      providerName: "aura",
      imgUrl: "./images/aura/TeenpattiTest(virtual).webp",
      redirectUrl: "/casino/aura-teenpatti-test-virtual",
    },
    "TeenpattiOpen(virtual)": {
      code: "67641",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Teenpatti Open (virtual)",
      name: "Teenpatti Open (virtual)",
      providerName: "aura",
      imgUrl: "./images/aura/TeenpattiOpen(virtual).webp",
      redirectUrl: "/casino/aura-teenpatti-open-virtual",
    },
    "CasinoWar(Virtual)": {
      code: "67581",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Casino War (Virtual)",
      name: "Casino War (Virtual)",
      providerName: "aura",
      imgUrl: "./images/aura/CasinoWar(Virtual).webp",
      redirectUrl: "/casino/aura-casino-war-virtual",
    },
    "Baccarat(Virtual)": {
      code: "92039",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Baccarat (Virtual)",
      name: "Baccarat (Virtual)",
      providerName: "aura",
      imgUrl: "./images/aura/Baccarat(Virtual).webp",
      redirectUrl: "/casino/aura-baccarat-virtual",
    },
    "BollywoodCasino(Virtual)": {
      code: "67571",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Bollywood Casino (Virtual)",
      name: "Bollywood Casino (Virtual)",
      providerName: "aura",
      imgUrl: "./images/aura/BollywoodCasino(Virtual).webp",
      redirectUrl: "/casino/aura-bollywood-casino-virtual",
    },
    "QueenRace(Virtual)": {
      code: "67621",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Queen Race (Virtual)",
      name: "Queen Race (Virtual)",
      providerName: "aura",
      imgUrl: "./images/aura/QueenRace.webp",
      redirectUrl: "/casino/aura-queen-race-virtual",
    },
  },
  instantGames: {
    Blastoff: {
      code: "67722",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Blastoff",
      name: "Blastoff",
      providerName: "aura",
      imgUrl: "./images/aura/Blastoff.webp",
      redirectUrl: "/casino/aura-blastoff",
    },
    FortuneWheel: {
      code: "70001",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Fortune Wheel",
      name: "Fortune Wheel",
      providerName: "aura",
      imgUrl: "./images/aura/FortuneWheel.webp",
      redirectUrl: "/casino/aura-fortune-wheel",
    },
    CoinToss: {
      code: "70003",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Coin Toss",
      name: "Coin Toss",
      providerName: "aura",
      imgUrl: "./images/aura/CoinToss.webp",
      redirectUrl: "/casino/aura-coin-toss",
    },
    Limbo: {
      code: "70004",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Limbo",
      name: "Limbo",
      providerName: "aura",
      imgUrl: "./images/aura/Limbo.webp",
      redirectUrl: "/casino/aura-limbo",
    },
    SlotGame: {
      code: "70005",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Slot Game",
      name: "Slot Game",
      providerName: "aura",
      imgUrl: "./images/aura/SlotGame.webp",
      redirectUrl: "/casino/aura-slot-game",
    },
    RockPaperScissors: {
      code: "70006",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Rock Paper Scissors",
      name: "Rock Paper Scissors",
      providerName: "aura",
      imgUrl: "./images/aura/RockPaperScissors.webp",
      redirectUrl: "/casino/aura-rock-paper-scissors",
    },
    Cricket: {
      code: "70008",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Cricket",
      name: "Cricket",
      providerName: "aura",
      imgUrl: "./images/aura/Cricket.webp",
      redirectUrl: "/casino/aura-cricket",
    },
    ClassicDice: {
      code: "70009",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Classic Dice",
      name: "Classic Dice",
      providerName: "aura",
      imgUrl: "./images/aura/ClassicDice.webp",
      redirectUrl: "/casino/aura-classic-dice",
    },
    Mines: {
      code: "70011",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Mines",
      name: "Mines",
      providerName: "aura",
      imgUrl: "./images/aura/Mines.webp",
      redirectUrl: "/casino/aura-mines",
    },
    Aviator: {
      code: "67722",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Aviator",
      name: "Aviator",
      providerName: "aura",
      imgUrl: "./images/aura/Aviator.webp",
      redirectUrl: "/casino/aura-aviator",
    },
  },
};
