import React, { PureComponent } from "react";
import Footer from "../../../../containers/Footer";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";

import BackIcon from "../../../../assets/svg/BackIcon";
import { useNavigate } from "react-router-dom";

const Affiliate = () => {
  const navigate = useNavigate();
  return (
    <div>
      <HeaderAfterLogin />

      <main className="main static-page">
        <div className="container">
          <div className="headingSec">
            <h4>Coming Soon</h4>
            {/* <div className="back-link" onClick={() => navigate(-1)}>
              <BackIcon /> Back
            </div> */}
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default Affiliate;
