import React from "react";
import { Container, Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { IoEye } from "react-icons/io5";
import BackIcon from "../../../../assets/svg/BackIcon";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Footer from "../../../../containers/Footer";
import { API_URLS } from "../../../../config/apiUrls";
import { useSWRFetcher } from "../../../../hooks/useSWRHooks";
import moment from "moment/moment";

const Bonus = () => {
  const navigate = useNavigate();

  const { data } = useSWRFetcher({
    url: API_URLS.BONUS.CLAIMS,
    isExternalFetch: true,
  });
  // ?skip=${0}&pagesize=${10}&startdate=&enddate=&sort[date]=asc

  return (
    <>
      <HeaderAfterLogin />

      <main className="main mt-4">
        <div className="container">
          <Container>
            <div className="headingSec">
              <h4>Bonus</h4>
              <div className="back-link" onClick={() => navigate(-1)}>
                <BackIcon /> Back
              </div>
            </div>
            <div className=" mt-4">
              <div className="table-responsive new_table">
                <Table className="table bonus_table " responsive>
                  <thead className="">
                    {/* <th width="">Name</th> */}
                    <th width="">Code</th>
                    <th>Bonus Type</th>
                    <th width="">Bonus Amount</th>
                    <th width="">Rollover amount </th>
                    {/* <th>Parts</th> */}
                    <th width="">Created date</th>
                    <th width="">View </th>
                  </thead>
                  <tbody>
                    {Array.isArray(data?.statement) &&
                      data?.statement.map((item, index) => (
                        <tr key={index}>
                          {/* <td>{item.bonusName}</td> */}
                          <td>{item.bonusCode}</td>
                          <td>{item.bonusType}</td>
                          <td>
                            {item.creditedAmount} / {item.creditableAmount}
                          </td>
                          <td>
                            {item.claimedAmount} / {item.goalAmount}
                          </td>
                          {/* <td>
                          {item.claimedParts} / {item.totalParts}
                        </td> */}
                          <td>
                            {" "}
                            {moment(item.createdAt).format("DD-MM-YY h:mm")}
                          </td>

                          <td>
                            {" "}
                            <Link to={`/bonus/${item.id}`}>
                              <IoEye className="eye_" />
                            </Link>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </Container>
        </div>
        <Footer />
      </main>
    </>
  );
};

export default Bonus;
