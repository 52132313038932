import React from "react";
import { useSelector } from "react-redux";
import Header from "../../../../containers/Header";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import Footer from "../../../../containers/Footer";

import VipPromotion1 from "../../../../assets/images/banner/promotions/VipPromotion1.webp";
import VipPromotion2 from "../../../../assets/images/banner/promotions/VipPromotion2.webp";
import VipPromotion3 from "../../../../assets/images/banner/promotions/VipPromotion3.webp";
import VipPromotion4 from "../../../../assets/images/banner/promotions/VipPromotion4.webp";

import VipGamesPromotion1 from "../../../../assets/images/banner/promotions/Aviator.webp";
import VipGamesPromotion2 from "../../../../assets/images/banner/promotions/LightningDice.webp";
import VipGamesPromotion3 from "../../../../assets/images/banner/promotions/BetGames.webp";
import VipGamesPromotion4 from "../../../../assets/images/banner/promotions/crazytime.webp";

import MoneyLeft from "../../../../assets/images/banner/promotions/money-left.webp";
import MoneyRight from "../../../../assets/images/banner/promotions/moner-right.webp";

function Promotions() {
  const { isAuth } = useSelector((state) => state.auth);

  return (
    <>
      {isAuth ? <HeaderAfterLogin /> : <Header />}

      <main className="main">
        <div className="vip-gifts promotions">
          <div className="inner-box">
            <div className="content-box ">
              <div className="heading">Promotions and bonuses</div>

              {/* <h5>Top 5 promoter of the month</h5> */}
              <div className="PromotionBox">
                <ul>
                  <div className="backgroundMoney BackgroundMoneyLeft">
                    <img src={MoneyLeft} />
                  </div>
                  <li>
                    <div className="">
                      <img src={VipPromotion1} alt="bronze" />
                    </div>
                  </li>
                  <li>
                    <div className="">
                      <img src={VipPromotion2} alt="silverr" />
                    </div>
                  </li>
                  <li>
                    <div className="">
                      <img src={VipPromotion3} alt="gold" />
                    </div>
                  </li>
                  <li>
                    <div className="">
                      <img src={VipPromotion4} alt="platinum" />
                    </div>
                  </li>
                  <div className="backgroundMoney BackgroundMoneyRight">
                    <img src={MoneyRight} />
                  </div>
                </ul>
              </div>
            </div>

            <div className="content-box PrmotionGameBox">
              <div className="heading">Games and bonuses</div>

              {/* <h5>Top 5 promoter of the month</h5> */}
              <ul className="">
                {/* <li className="bonus-card">
                  <div className="BonusMainImg">
                    <div className="BonusMainContent">
                      <p>1win Lucky Drive</p>
                    </div>
                    <img src={SiteLogo} alt="bronze" className="logo" />
                  </div>
                </li> */}
                <li>
                  <div className="">
                    <img src={VipGamesPromotion1} alt="silverr" />
                  </div>
                </li>
                <li>
                  <div className="">
                    <img src={VipGamesPromotion2} alt="silverr" />
                  </div>
                </li>
                <li>
                  <div className="">
                    <img src={VipGamesPromotion3} alt="gold" />
                  </div>
                </li>
                <li>
                  <div className="">
                    <img src={VipGamesPromotion4} alt="platinum" />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </main>

      {!isAuth ? <BeforeLoginFooter /> : <Footer />}
    </>
  );
}

export default Promotions;
