import React, { useEffect, useState } from "react";
import {
  getAllGateways,
  setSelectedGateway,
} from "../../../../redux/user/actions";
import { useDispatch, useSelector } from "react-redux";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import DepositGateway from "./depositGateway";
import { Navigate, useNavigate } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import DepositImg from "../../../../assets/images/icons/dc.png";
import bankImg from "../../../../assets/images/icons/bank.png";
import bitcoinActive from "../../../../assets/images/icons/bitcoinActive.svg";
import bitcoinDisable from "../../../../assets/images/icons/bitcoinDisable.svg";
import Active from "../../../../assets/images/icons/Active.svg";
import Deactive from "../../../../assets/images/icons/Deactive.svg";

import { getActiveBankDetails } from "../../../../redux/app/actions";
import BackIcon from "../../../../assets/svg/BackIcon";
import { Container } from "react-bootstrap";

const GatewaysList = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});

  const { gatewaysList } = useSelector((state) => state.user);
  const { bankDetails } = useSelector((state) => state.app);
  const { user } = useSelector((state) => state.auth);
  const { selectedGateway } = useSelector((state) => state.user);
  const [gateway, setGateway] = useState("");
  const isDemoUser = user?.mstruserid === process.env.REACT_APP_DEMO_USER_ID;
  const [isActive, setIsActive] = useState(false);
  const [isCssActive, setIsCssActive] = useState(false);
  const isAutoGatewayExist = gatewaysList?.deposits?.some(
    (f) => f?.type === "auto"
  );

  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getAllGateways());
    dispatch(getActiveBankDetails());
  }, []);

  const handleGatewayClick = (item) => {
    dispatch(setSelectedGateway(item));
    setIsActive(false);
    setIsCssActive(true);
    setFormData({});
    setGateway("");
    // navigate(`/deposit/${item.name}`);
  };

  const handleBankTransferClick = () => {
    // navigate(`/deposit/bank_transfer`);
    setIsActive(true);
    setIsCssActive(true);

    setGateway("bank_transfer");
  };
  return (
    <div>
      {isDemoUser && <Navigate to="/" />}
      <HeaderAfterLogin />
      <main className="main DepositPageSection">
        <Container>
          <div className="headingSec">
            <h4>Deposit</h4>
            <div className="back-link" onClick={() => navigate(-1)}>
              <BackIcon /> Back
            </div>
          </div>

          <div
            className={
              isCssActive
                ? "DepositMainSection DepositMainSectionActive"
                : "d-flex DepositMainSection"
            }
          >
            {" "}
            <div
              className={
                isCssActive
                  ? "DepositMainCard DepositMainCardActive"
                  : "DepositMainCard"
              }
            >
              {gatewaysList?.deposits
                ?.sort((a, b) => a?.order - b?.order)
                .map((item, index) => {
                  return (
                    <div
                      className={
                        selectedGateway?.code === item?.code && !isActive
                          ? "cards1 active"
                          : "cards1 "
                      }
                      onClick={() => {
                        handleGatewayClick(item);
                      }}
                      key={index}
                    >
                      <div className="card-icon">
                        <img
                          src={item?.logo || DepositImg}
                          style={{ width: "60px" }}
                        />
                      </div>

                      <p>{item?.name}</p>
                    </div>
                  );
                })}
              {bankDetails && (
                <div
                  className={isActive ? "cards1 active " : "cards1"}
                  onClick={() => {
                    handleBankTransferClick();
                  }}
                >
                  {" "}
                  <div className="logo">
                    <img src={isActive ? Active : Deactive} />
                  </div>
                  <div className="card-icon">
                    <img
                      src={isActive ? bitcoinActive : bitcoinDisable}
                      style={{ width: "66px" }}
                    />
                  </div>
                  <p style={{}}>{"Bank Transfer"}</p>
                </div>
              )}
            </div>
            <div
              className={
                isCssActive ? "cardsContent cardsContentActive" : "cardsContent"
              }
            >
              <DepositGateway
                formData={formData}
                setFormData={setFormData}
                gateway={gateway}
                setIsCssActive={setIsCssActive}
              />
            </div>
          </div>

          <div className="gatewaySection d-none">
            <div className="gatewaypart">
              <Tabs
                defaultActiveKey={isAutoGatewayExist ? "Deposit" : "bank"}
                id="uncontrolled-tab-example"
                className=""
              >
                <Tab eventKey="Deposit" title="Online Deposit">
                  {gatewaysList?.deposits
                    ?.sort((a, b) => a?.order - b?.order)
                    ?.filter((f) => f?.type === "auto")
                    .map((item, index) => {
                      return (
                        <div
                          className="cards1"
                          onClick={() => {
                            handleGatewayClick(item);
                          }}
                          key={index}
                        >
                          <div className="card-icon">
                            <img src={DepositImg} />
                          </div>

                          <p style={{ color: "#fff" }}>{item?.name}</p>
                        </div>
                      );
                    })}
                </Tab>

                <Tab eventKey="bank" title="Bank Transfer">
                  {bankDetails && (
                    <div
                      className="cards1"
                      onClick={() => {
                        handleBankTransferClick();
                      }}
                    >
                      {" "}
                      <div className="card-icon">
                        <img src={bankImg} style={{ width: "66px" }} />
                      </div>
                      <p style={{ color: "#fff" }}>{"Bank Transfer"}</p>
                    </div>
                  )}

                  {gatewaysList?.deposits
                    ?.sort((a, b) => a?.order - b?.order)
                    ?.filter((f) => f?.type === "manual")
                    .map((item, index) => {
                      return (
                        <div
                          className="cards1"
                          onClick={() => {
                            handleGatewayClick(item);
                          }}
                          key={index}
                        >
                          <div className="card-icon">
                            <img src={DepositImg} />
                          </div>
                          <p style={{ color: "#fff" }}>{item?.name}</p>
                        </div>
                      );
                    })}
                </Tab>
              </Tabs>
            </div>
          </div>
        </Container>
      </main>
    </div>
  );
};
export default GatewaysList;
