import React, { PureComponent } from "react";
import Footer from "../../../../containers/Footer";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";

import BackIcon from "../../../../assets/svg/BackIcon";
import { useNavigate } from "react-router-dom";

function Terms() {
  const navigate = useNavigate();

  return (
    <>
      <HeaderAfterLogin />

      <main className="main static-page">
        <div className="container">
          <div className="headingSec">
            <h4> Terms & Conditions</h4>
            <div className="back-link" onClick={() => navigate(-1)}>
              <BackIcon /> Back
            </div>
          </div>
          <div className="content">
            <h5>Betting Rules</h5>
            <p>
              The following Betting Rules pertaining to the bookmaker RoyalKing1
              (here in after referred to as the Rules) stipulate the manner of
              accepting bets, paying winnings and resolving disputes, as well as
              the specific features of certain bets on certain sports. These
              Rules shall govern any other relations between the bookmaker
              RoyalKing1 and the customer.
            </p>
            <p>
              These Rules shall apply to betting on the website and at
              RoyalKing1 betting facilities.
            </p>
            <p className="privacy-points">
              1. Bet is a risk-driven agreement for potential winnings entered
              into between the customer and the bookmaker under the established
              Rules, where the fulfillment of such agreement is conditioned by
              an event whose outcome is yet to be determined. Bets are accepted
              on the conditions offered by the bookmaker.
            </p>
            <p className="privacy-points">
              2. Outcome is the result of the event (events) on which the bet
              was placed.
            </p>
            <p className="privacy-points">
              3. Customer is an individual placing a bet with the bookmaker on
              an outcome.
            </p>
            <p className="privacy-points">
              4. Bet Cancellation is an outcome on which the bet is not settled
              and winnings are not paid. As per the Rules, in the event of “bet
              cancellation”, an arrangement between the bookmaker and the
              customer shall be deemed unconcluded and the stake shall be
              refunded.
            </p>
            <p className="privacy-points">
              5. Regular Time is the duration of the match subject to the
              regulations of the relevant sport, including time added by the
              referee. Regular time does not include extra time, overtime(s),
              penalty shootouts, etc.
            </p>
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
}

export default Terms;
