import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { useSelector } from "react-redux";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Header from "../../../../containers/Header";
import { casinoSection as casinoData } from "../../../../lib/data/casinoSection.data";
import { auraGames } from "../../../../lib/data/auraGames.data";
import GamesSearchModal from "../../../components/GamesSearchModal";
import BottomNav from "../../../../containers/BottomNav";

import Filter from "../../../../assets/images/icons/filter.png";
import CloseIcon from "../../../../assets/images/icons/close-icon.webp";

import RouletteCat from "../../../../assets/images/categories/Roulette.webp";
import BaccaratCat from "../../../../assets/images/categories/Baccarat.webp";
import BlackjackCat from "../../../../assets/images/categories/Blackjack.webp";
import DragonTigerCat from "../../../../assets/images/categories/DragonTiger.webp";
import PokerCat from "../../../../assets/images/categories/Poker.webp";
import SpribeCat from "../../../../assets/images/categories/Spribe.webp";
import SupernowaCat from "../../../../assets/images/categories/Supernowa.webp";
import AndarBaharCat from "../../../../assets/images/categories/AndarBahar.webp";
import PopularCat from "../../../../assets/images/categories/Popular.webp";

import Slide1 from "../../../../assets/images/banner/live-games/banner1.webp";
import Slide2 from "../../../../assets/images/banner/live-games/banner2.webp";

const LiveCasino = () => {
  const { isAuth } = useSelector((state) => state.auth);
  const { banners } = useSelector((state) => state.app);
  const casinoSection = { ...casinoData, ...auraGames };
  const [selectedProvider, setSelectedProvider] = useState({
    id: 1,
    name: "All",
    eventKey: "all",
  });
  const [activeTab, setActiveTab] = useState({
    id: 1,
    name: "Live Casino",
    key: "popularGames",
    imgSrc: PopularCat,
  });
  const [customActiveTab, setCustomActiveTab] = useState(null);
  const [allGamesData, setAllGamesData] = useState(null);
  const [providerName, setProviderName] = useState(null);

  const providersArray = [
    { id: 1, name: "All", eventKey: "all" },
    { id: 2, name: "Evolution", eventKey: "evolution" },
    { id: 3, name: "Ezugi", eventKey: "ezugi" },
    { id: 4, name: "Vivo", eventKey: "vivo" },
    { id: 5, name: "Spribe", eventKey: "spribe" },
    { id: 6, name: "Supernowa", eventKey: "supernowa" },
  ];
  const tabsArray = [
    {
      id: 1,
      name: "Popular Games",
      key: "popularGames",
      imgSrc: PopularCat,
    },
    {
      id: 2,
      name: "Andar Bahar",
      key: "AndarBahar",
      imgSrc: AndarBaharCat,
    },
    {
      id: 3,
      name: "Roulette",
      key: "roulettetab",
      imgSrc: RouletteCat,
    },
    {
      id: 4,
      name: "Dragon Tiger",
      key: "dragontiger",
      imgSrc: DragonTigerCat,
    },
    {
      id: 5,
      name: "Baccarat",
      key: "baccarattab",
      imgSrc: BaccaratCat,
    },
    {
      id: 6,
      name: "Blackjack",
      key: "blackjacktab",
      imgSrc: BlackjackCat,
    },
    {
      id: 7,
      name: "Poker",
      key: "pokertab",
      imgSrc: PokerCat,
    },
    {
      id: 8,
      name: "Spribe",
      key: "spribe",
      imgSrc: SpribeCat,
    },
    {
      id: 9,
      name: "Supernowa",
      key: "supernowa",
      imgSrc: SupernowaCat,
    },
  ];
  function scrollToElement(elementId) {
    var targetElement = document.getElementById(elementId);

    if (targetElement) {
      var offsetTop = targetElement.getBoundingClientRect().top;
      var offset = offsetTop - 100;

      var maxScrollTop =
        document.documentElement.scrollHeight - window.innerHeight;
      var targetScrollTop = window.scrollY + offset;

      if (targetScrollTop <= maxScrollTop) {
        window.scrollTo({
          top: targetScrollTop,
          behavior: "smooth",
        });
      }
    }
  }
  const handleSelect = (eventKey) => {
    setProviderName(null);

    const provider =
      providersArray.find((p) => p?.eventKey === eventKey) || "all";
    setSelectedProvider(provider);
    manageActiveTab(provider);
  };
  useEffect(() => {
    filterAllGamesData();
  }, []);
  const manageActiveTab = (provider) => {
    setCustomActiveTab({
      id: 1,
      name: "Live Casino",
      key: "allGames",
      imgSrc: RouletteCat,
    });
    filterAllGamesData(provider.eventKey);
  };

  const getTabActiveClass = (tab) => {
    if (customActiveTab) {
      return tab.key === customActiveTab.key;
    }
    return tab.key === activeTab?.key;
  };
  const filterAllGamesData = (provider = selectedProvider?.eventKey) => {
    let emptyArray = {};
    Object.keys(casinoSection).map((key) => {
      // console.log("key", key);
      if (
        key !== "allGames" &&
        tabsArray.some((s) => s.key === key) &&
        typeof casinoSection[key] !== "string"
      ) {
        emptyArray = { ...emptyArray, ...casinoSection[key] };
      }
    });
    let filteredValues;
    if (provider !== "all") {
      filteredValues = Object.values(emptyArray).filter(
        (f) => f.providerName?.toLowerCase() === provider
      );
    } else {
      filteredValues = Object.values(emptyArray).filter(
        (f) =>
          f.providerName?.toLowerCase() === "evolution" ||
          f.providerName?.toLowerCase() === "ezugi" ||
          f.providerName?.toLowerCase() === "vivo" ||
          f.providerName?.toLowerCase() === "aura"
      );
    }

    setAllGamesData(filteredValues);
  };

  const settings = {
    dots: false,
    fade: false,
    arrows: true,
    autoplay: true,
    infinite: true,
    speed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const [isActive, setIsActive] = useState(false);
  const toggleClass = () => {
    setIsActive(!isActive);
  };

  const htmlElement = document.documentElement;
  useEffect(() => {
    if (isActive) {
      htmlElement.style.overflow = "hidden";
    } else {
      htmlElement.style.overflow = "auto";
    }
    return () => {
      htmlElement.style.overflow = "auto";
    };
  }, [isActive]);

  return (
    <>
      {isAuth ? <HeaderAfterLogin /> : <Header />}

      <main className="main">
        <div className="LiveCasinoSection pb-0">
          {/* <CasinoGamesSection /> */}
          <div className={`casino-games ${isActive ? "games-filter" : ""}`}>
            <div className="games-type">
              <div className="SearchGames">
                <GamesSearchModal />

                <a
                  href="#"
                  className="filter-toggle d-md-none"
                  onClick={toggleClass}
                >
                  <img src={CloseIcon} alt="close" />
                </a>
              </div>

              <div className="inner-box">
                <div className="game-filter">
                  <ul className="categories">
                    <h6>Categories</h6>
                    {tabsArray?.map((tab, index) => {
                      return (
                        <React.Fragment key={index}>
                          {((casinoSection?.[tab?.key] &&
                            Object.values(casinoSection?.[tab?.key]).some(
                              (s) =>
                                s?.providerName?.toLowerCase() ===
                                selectedProvider?.eventKey
                            )) ||
                            selectedProvider?.eventKey === "all" ||
                            tab?.key == "GamesAll") && (
                            <li
                              onClick={() => {
                                setCustomActiveTab(null);
                                if (tab.key === "allGames") {
                                  filterAllGamesData();
                                }
                                setActiveTab(tab);
                                scrollToElement("all-images");
                                setProviderName(null);
                              }}
                              // key={index}
                            >
                              <a
                                href="#"
                                className={
                                  getTabActiveClass(tab) ? "active" : ""
                                }
                              >
                                <img src={tab?.imgSrc} alt="roulette" />
                                <span>{tab?.name}</span>
                              </a>
                            </li>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </ul>

                  <ul className="providers">
                    <h6>Providers</h6>
                    {providersArray.map((p, index) => {
                      return (
                        <li key={index}>
                          <a
                            href="#"
                            onClick={() => {
                              handleSelect(p?.eventKey);
                              setProviderName(p?.name);
                            }}
                          >
                            <span>{p?.name}</span>
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="BannerSection">
            <div className="bannerSec d-none d-md-block">
              <div className="banners casino live">
                <div className="header-carousel casino">
                  <Slider {...settings}>
                    <div>
                      <div className="banner">
                        <img src={Slide1} alt="first slide" />
                        {/* <div className="banner-content">content 1...</div> */}
                      </div>
                    </div>
                    <div>
                      <div className="banner">
                        <img src={Slide2} alt="second slide" />
                        {/* <div className="banner-content">content 2...</div> */}
                      </div>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>

            <div className="all-images" id="all-images">
              <di className="games-heading">
                <h3>{providerName || Object.values([activeTab?.name])}</h3>
                <a
                  href="#"
                  className="filter-toggle d-md-none"
                  onClick={toggleClass}
                >
                  <img src={Filter} alt="filter" />
                  Filter
                </a>
              </di>

              <ul className="gamelisting">
                {casinoSection?.[activeTab?.key] &&
                activeTab?.key !== "allGames" &&
                customActiveTab?.key !== "allGames"
                  ? Object.values(casinoSection?.[activeTab?.key]).map(
                      (casino, index) => {
                        return (
                          <React.Fragment key={index}>
                            {(selectedProvider?.eventKey === "all" ||
                              casino?.providerName?.toLowerCase() ===
                                selectedProvider.eventKey) && (
                              <li key={index}>
                                <a href={casino?.redirectUrl}>
                                  <img src={casino?.imgUrl} />

                                  <div className="providerName">
                                    {casino?.providerName}
                                  </div>
                                  <div className="GameName">{casino?.name}</div>
                                </a>
                              </li>
                            )}
                          </React.Fragment>
                        );
                      }
                    )
                  : allGamesData &&
                    allGamesData.map((casino, index) => {
                      return (
                        <li key={index}>
                          <a href={casino?.redirectUrl}>
                            <img src={casino?.imgUrl} />
                            <div className="providerName">
                              {casino?.providerName}
                            </div>
                            <div className="GameName">{casino?.name}</div>
                          </a>
                        </li>
                      );
                    })}
              </ul>
            </div>
          </div>
        </div>
      </main>

      <BottomNav />
    </>
  );
};

export default LiveCasino;
